import { get, post, download, del, put } from './index';


/**
 * 获取合同信息
 * @param data
 * @returns {Promise<unknown>}
 */
 export const getContract = (data) => {
  return get('/system/contract/getContract', data).then((res) => res);
};

/**
 * 获取合同列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const list = (data) => {
  return get('/system/contract/searchContractPage', data).then((res) => res);
};

/**
 * 获取合同金额变更明细列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const searchContractChangeList = (data) => {
  return get('/system/contract/searchContractChangeList', data).then((res) => res);
};

/**
 * 保存合同信息
 * @param data
 * @returns {Promise<unknown>}
 */
 export const saveContract = (data) => {
  return post('/system/contract/saveContract', data).then((res) => res);
};

/**
 * 删除合同信息
 * @param param
 * @returns {Promise<unknown>}
 */
 export const deleteContract = (id) => {
  return del(`/system/contract/deleteContract/${id}`).then((res) => res);
};

/**
 * 批量删除合同信息
 * @param param
 * @returns {Promise<unknown>}
 */
 export const deleteContractByIds = (ids) => {
  // return del(`/system/contract/batchDeleteContract/${ids}`).then((res) => res);
};

/**
 * 导入合同信息
 * @param data
 * @returns {Promise<unknown>}
 */
 export const importContract = (data) => {
  return post('/system/contract/importContract', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

/**
 * 导出
 * @param data
 * @returns {Promise<unknown>}
 */
 export const exportContract = (data) => {
  return download('/system/contract/exportContract', data).then((res) => res);
};

 