import { get, post, download, del, put } from './index';


/**
 * 获取项目列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const list = (data) => {
  return get('/system/project/searchProjectPage', data).then((res) => res);
};

/**
 * 保存项目信息
 * @param data
 * @returns {Promise<unknown>}
 */
 export const saveProject = (data) => {
  return post('/system/project/saveProject', data).then((res) => res);
};

/**
 * 删除项目信息
 * @param param
 * @returns {Promise<unknown>}
 */
 export const deleteProject = (id) => {
  return del(`/system/project/deleteProject/${id}`).then((res) => res);
};

/**
 * 批量删除项目信息
 * @param param
 * @returns {Promise<unknown>}
 */
 export const deleteProjectByIds = (ids) => {
  // return del(`/system/project/batchDeleteProject/${ids}`).then((res) => res);
};

/**
 * 导入项目信息
 * @param data
 * @returns {Promise<unknown>}
 */
 export const importProject = (data) => {
  return post('/system/project/importProject', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

/**
 * 导出
 * @param data
 * @returns {Promise<unknown>}
 */
 export const exportProject = (data) => {
  return download('/system/project/exportProject', data).then((res) => res);
};

 

/**
 * 获取项目涉及预交信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const getProjectPrepayByProjectId = (params) => {
  return get('/system/project/getProjectPrepayByProjectId', params).then((res) => res);
};


/**
 * 获取项目施工信息
 * @param data
 * @returns {Promise<unknown>}
 */
 export const getProjectConstructionByProjectId = (params) => {
  return get('/system/project/getProjectConstructionByProjectId', params).then((res) => res);
};
