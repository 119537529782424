<template>
  <div class="content-wrap">
    <div class="create-header">
      <div class="header-top">
        <span>{{ title }}</span>
        <div></div>
      </div>
      <div class="header-bottom">
        <el-form ref="projectForm" :model="formInfo" :rules="ruless" label-width="140px">
          <el-row>
            <el-col :span="8">
            </el-col>
            <el-col :span="8">
              <el-form-item prop="orgId" required label="所属组织">
                <organization-select :model="formInfo" :filterable="true" :disabled="handIsDisabled()" all-label="请选择所属组织" :is-add-all="false" ></organization-select>
              </el-form-item>
              <!-- <el-form-item label="所属组织" prop="orgName" :required="true"> -->
                <!-- <organization-select :model="formInfo" :disabled="propIsDisabled(formInfo, 'orgId')" allLabel="请选择所属组织"></organization-select> -->
                <!-- <el-input v-model.trim="formInfo.orgId" v-show="false" maxlength="128"/> -->
                <!-- <el-input v-model.trim="formInfo.orgName" maxlength="128"/> -->
              <!-- </el-form-item> -->
            </el-col>
            <el-col :span="8">
             <el-form-item
                class="select-width"
                label="项目涉税类型"
                prop="taxRelatedType"
                :required="true"
              >
                <el-select v-model="formInfo.taxRelatedType" @change="changeNoYes">
                  <el-option v-for="item in taxRelatedTypeOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
             <el-form-item
                class="select-width"
                label="项目编码"
                prop="projectCode"
                :required="true"
              >
                <el-input v-model="formInfo.projectCode" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="项目名称"
                prop="projectName"
                :required="true"
              >
                <el-input v-model="formInfo.projectName" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="计税方式"
                prop="taxMethod"
                :required="true"
              >
              <el-select v-model="formInfo.taxMethod" @change="changeNoYes">
                <el-option v-for="item in taxMethodOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="纳税人识别号" prop="nsrsbh" :required="true">
                <el-input v-model="formInfo.nsrsbh" maxlength="32"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纳税人名称" :required="true">
                <el-input v-model="formInfo.nsrmc"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="是否预交"
                prop="isPrepay"
              >
              <el-select v-model="formInfo.isPrepay" @change="changeNoYes">
                <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="核算单位部门名称"
                prop="accountOrgName"
              >
                <el-input v-model="formInfo.accountOrgName" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="相对方纳税人识别号"
                prop="customerNsrsbh"
                label-width="20"
              >
                <el-input v-model="formInfo.customerNsrsbh" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="相对方名称"
                prop="relativeName"
              >
                <el-input v-model="formInfo.relativeName" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="开户银行及账号" prop="bankAndAccount">
                <el-input v-model="formInfo.bankAndAccount" maxlength="32"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开票地址及电话">
                <el-input v-model="formInfo.addressAndPhone"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="期初已开票金额"
                prop="invoiceAmount"
              >
                <el-input-number
                  v-model="formInfo.invoiceAmount"
                  :min="0"
                  :controls="false"
                  :precision="2"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="是否跨区域"
                prop="isCrossRegion"
              >
              <el-select v-model="formInfo.isCrossRegion" @change="changeNoYes">
                <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
            </el-col>
            <el-col :span="8">
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="formInfo.remark"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
       </div>
     </div>

      <div class="create-main">
        <div class="header-top">
          <span>合同列表</span>
          <div class="top-right">
            <el-button type="primary" @click="handleContract">参照合同</el-button>
          </div>
        </div>
        <div class="license-body">
          <el-table ref="contractInfoTable" :data="contractRecords" :header-cell-style="handleHeaderCellStyle" border max-width="800">
            <el-table-column type="index" label="序号" width="60">
              </el-table-column>
            <el-table-column show-overflow-tooltip label="合同编号" 
              prop="contractCode" min-width="120">
            </el-table-column>
            <el-table-column
              prop="contractName"
              label="合同名称"
              align="center"
              min-width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="customerNsrsbh"
              label="相对方纳税人识别号"
              align="center"
              min-width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="relativeName"
              label="相对方名称"
              align="center"
              min-width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="allAmount"
              label="合同金额（含税）"
              align="center"
              min-width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="writeTime"
              label="合同签订日期"
              align="center"
              min-width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="contractStartTime"
              label="生效日期"
              align="center"
              min-width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="contractType"
              label="合同类型 "
              align="center"
              min-width="120"
              :formatter="contractTypeFormat"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="合同状态"
              align="center"
              min-width="120"
              :formatter="statusFormat"
              :show-overflow-tooltip="true"
            ></el-table-column>
          </el-table>
        </div>
      </div>

      <div class="create-main">
        <div class="header-top">
          <span>涉及预交</span>
        </div>
        <!-- <div class="license-body"> -->
          <el-form ref="projectPrepayForm" :model="projectPrepayFormInfo" :rules="ruless" label-width="100px">
            
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="">
              </el-form-item>
              <el-form-item label-width="120px" label="计税依据" prop="">
              </el-form-item>
              <el-form-item label-width="120px" label="税率/单位税额" prop="">
              </el-form-item>
              <el-form-item label-width="10px" label="" prop="">
              </el-form-item>
              <el-form-item label-width="120px" label="计税依据" prop="">
              </el-form-item>
              <el-form-item label-width="120px" label="税率/单位税额" prop="">
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isVatTax">
                <el-checkbox v-model="projectPrepayFormInfo.isVatTax">
                    增值税预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="20px" label="" prop="vatTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.vatTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="vatTax">
                <el-select v-model="projectPrepayFormInfo.vatTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px" label="" prop="isUrbanTax" style="margin-left: 50px;">
                <el-checkbox v-model="projectPrepayFormInfo.isUrbanTax">
                    城建税预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="urbanTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.urbanTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="urbanTax">
                <el-select v-model="projectPrepayFormInfo.urbanTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isEduAttachTax">
                <el-checkbox v-model="projectPrepayFormInfo.isEduAttachTax">
                    教育费预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="eduAttachTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.eduAttachTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="eduAttachTax">
                <el-select v-model="projectPrepayFormInfo.eduAttachTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px" label="" prop="isLocalEduTax" style="margin-left: 50px;">
                <el-checkbox v-model="projectPrepayFormInfo.isLocalEduTax">
                    地方教育预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="localEduTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.localEduTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="localEduTax">
                <el-select v-model="projectPrepayFormInfo.localEduTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isCorpIncomeTax">
                <el-checkbox v-model="projectPrepayFormInfo.isCorpIncomeTax">
                    企业所得税预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="20px" label="" prop="corpIncomeTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.corpIncomeTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="corpIncomeTax">
                <el-select v-model="projectPrepayFormInfo.corpIncomeTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="20px" label="" prop="isStampTax" style="margin-left: 50px;">
                <el-checkbox v-model="projectPrepayFormInfo.isStampTax">
                    印花税预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="stampTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.stampTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="stampTax">
                <el-select v-model="projectPrepayFormInfo.stampTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isWaterConservancyFundTax">
                <el-checkbox v-model="projectPrepayFormInfo.isWaterConservancyFundTax">
                    水利基金预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="waterConservancyFundTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.waterConservancyFundTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="waterConservancyFundTax">
                <el-select v-model="projectPrepayFormInfo.waterConservancyFundTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px" label="" prop="isPersonalTax" style="margin-left: 50px;">
                <el-checkbox v-model="projectPrepayFormInfo.isPersonalTax">
                    个人所得税预交税率
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="personalTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.personalTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="personalTax">
                <el-select v-model="projectPrepayFormInfo.personalTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isDisabilitiesFundTax">
                <el-checkbox v-model="projectPrepayFormInfo.isDisabilitiesFundTax">
                    残疾人就业保障金
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="disabilitiesFundTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.disabilitiesFundTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="disabilitiesFundTax">
                <el-select v-model="projectPrepayFormInfo.disabilitiesFundTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px" label="" prop="isUnionDuesTax" style="margin-left: 50px;">
                <el-checkbox v-model="projectPrepayFormInfo.isUnionDuesTax">
                    工会经费
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="unionDuesTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.unionDuesTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="unionDuesTax">
                <el-select v-model="projectPrepayFormInfo.unionDuesTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isResourceTax">
                <el-checkbox v-model="projectPrepayFormInfo.isResourceTax">
                    资源税
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="resourceTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.resourceTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="resourceTax">
                <el-select v-model="projectPrepayFormInfo.resourceTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px" label="" prop="isRefuseDisposalTax" style="margin-left: 50px;">
                <el-checkbox v-model="projectPrepayFormInfo.isRefuseDisposalTax">
                    城市生活垃圾处置
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="refuseDisposalTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.refuseDisposalTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="refuseDisposalTax">
                <el-select v-model="projectPrepayFormInfo.refuseDisposalTax" @change="changeNoYes">
                  <el-option v-for="item in taxRateOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="create-main-row">
              <el-form-item label-width="10px" label="" prop="isEnvironmentalProtectionTax">
                <el-checkbox v-model="projectPrepayFormInfo.isEnvironmentalProtectionTax">
                    环保税
                </el-checkbox>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="environmentalProtectionTaxationBasis">
                <el-select v-model="projectPrepayFormInfo.environmentalProtectionTaxationBasis" @change="changeNoYes">
                  <el-option v-for="item in taxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="environmentalProtectionTax">
                <el-input v-model="projectPrepayFormInfo.environmentalProtectionTax" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="">
                <el-input disabled clearable></el-input>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="">
                <el-input disabled clearable></el-input>
              </el-form-item>
              <el-form-item label-width="2px" label="" prop="">
                <el-input disabled clearable></el-input>
              </el-form-item>
            </el-row>

          </el-form>
        <!-- </div> -->
      </div>

      <div class="create-main">
        <div class="header-top">
          <span>施工信息</span>
        </div>
        <div class="header-bottom">
          <el-form ref="projectConstructionForm" :model="projectConstructionFormInfo" :rules="ruless" label-width="140px">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="permitCode" label="施工许可证号">
                  <el-input v-model.trim="projectConstructionFormInfo.permitCode" maxlength="128"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发证机关" prop="certificateAgency">
                  <el-input v-model.trim="projectConstructionFormInfo.certificateAgency" maxlength="128"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="是否报验登记"
                  prop="taxRelatedType"
                >
                  <el-select v-model="projectConstructionFormInfo.taxRelatedType" @change="changeNoYes">
                    <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
              <el-form-item
                  class="select-width"
                  label="发证或合同签订时间"
                  prop="certificateTime"
                >
                <el-date-picker v-model="projectConstructionFormInfo.certificateTime" style="width: 220px" value-format="yyyy-MM-dd" 
                  placeholder="选择合同截止日期" type="date" >
                </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  class="select-width"
                  label="项目地址"
                  prop="projectAddress"
                >
                  <el-select v-model="projectConstructionFormInfo.county" @change="changeNoYes">
                    <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-select v-model="projectConstructionFormInfo.province" @change="changeNoYes">
                    <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-select v-model="projectConstructionFormInfo.city" @change="changeNoYes" width="100">
                    <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="项目详细地址" prop="nsrsbh">
                  <el-input v-model="projectConstructionFormInfo.projectDetailedAddress" maxlength="32"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="开工日期">
                <el-date-picker v-model="projectConstructionFormInfo.startDate" style="width: 220px" value-format="yyyy-MM-dd" 
                  placeholder="选择合同截止日期" type="date" >
                </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="预计竣工时间"
                  prop="expectedEndDate"
                >
                <el-date-picker v-model="projectConstructionFormInfo.expectedEndDate" style="width: 220px" value-format="yyyy-MM-dd" 
                  placeholder="选择合同截止日期" type="date" >
                </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="完成进度"
                  prop="progress"
                >
                  <el-input v-model="projectConstructionFormInfo.progress" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="建设规模（平方米）"
                  prop="buildingScale"
                  label-width="100"
                >
                  <el-input v-model="projectConstructionFormInfo.buildingScale" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="实际完成工期"
                  prop="endDate"
                >
                <el-date-picker v-model="projectConstructionFormInfo.endDate" style="width: 220px" value-format="yyyy-MM-dd" 
                  placeholder="选择合同截止日期" type="date" >
                </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="项目状态"
                  prop="projectStatus"
                >
                <el-select v-model="projectConstructionFormInfo.projectStatus" @change="changeNoYes">
                  <el-option v-for="item in projectStatusOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
              </el-col>
              <el-col :span="8">
              </el-col>
            </el-row>
          </el-form>

        </div>
      </div>

      <div class="edit-select">
        <div class="edit-select-foot">
          <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
          <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveProject" type="primary">保存</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import {tableStyle} from '@/util/mixins';
import RegexLib from '@/assets/js/regex-lib'
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import {
  checkproject,
  checkprojectUniqueCode,
  ReqprojectGetSelectList,
  saveProject,
  selectproject,
  getProjectPrepayByProjectId,
  getProjectConstructionByProjectId
} from '@/service/project'
import {list} from '@/service/contract.js';
import {getInfosByOrgId} from '@/service/paas/paas-service'

export default {
  name: 'create',
  components: { OrganizationSelect },
  mixins: [tableStyle],
  props: {
    lockObject: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      tableData: [],
      // chanelData: [
      //   {
      //     billingChannel: 'QD-RPA',
      //     billingChannelName: '全电-RPA',
      //     enabled: false,
      //     appId: '',
      //     appSecret: '',
      //     applyType: false
      //   },
      //   {
      //     billingChannel: 'QD-XSD',
      //     billingChannelName: '机器人X版',
      //     enabled: false,
      //     appId: '',
      //     appSecret: '',
      //     applyType: false
      //   },
      //   {
      //     billingChannel: 'QD-LQ',
      //     billingChannelName: '全电-乐企',
      //     enabled: false,
      //     appId: '',
      //     appSecret: '',
      //     applyType: false
      //   },
      // ],
      //项目涉税类型
      taxRelatedTypeOptions: [
        {
          label: "建筑服务",
          value: 1
        },
        {
          label: "出租不动产",
          value: 2
        },
        {
          label: "销售不动产",
          value: 3
        },
      ],
      //计税方式
      taxMethodOptions: [
        {
          label: "一般计税",
          value: 1
        },
        {
          label: "简易征收（老项目）",
          value: 2
        },
        {
          label: "简易征收（甲供材）",
          value: 3
        },
        {
          label: "简易征收（其他）",
          value: 4
        },
      ],
      //项目状态
      projectStatusOptions: [
        {
          label: "开工",
          value: 1
        },
        {
          label: "在建",
          value: 2
        },
        {
          label: "停工",
          value: 3
        },
        {
          label: "完工未核销",
          value: 4
        },
        {
          label: "核销",
          value: 5
        },
      ],
      taxOptions: [
        {
          value: 0,
          label: '不含税金额'
        }, {
          value: 1,
          label: '含税金额'
        }
      ],
      taxRateOptions: [
        {
          value: 0.002,
          label: '2‰'
        }, {
          value: 0.0025,
          label: '2.5‰'
        }, {
          value: 0.004,
          label: '4‰'
        }, {
          value: 0.03,
          label: '3%'
        }, {
          value: 0.06,
          label: '6%'
        }, {
          value: 0.015,
          label: '15%'
        }
      ],
      options: [
        {
          value: 0,
          label: '否'
        }, {
          value: 1,
          label: '是'
        }
      ],
      title: '项目信息',
      isEdit: false,
      imageUrl: '',
      formInfo: this.lockObject,
      contractRecords: [],
      projectConstructionFormInfo: {
        id: null,
        projectId: null,
        permitCode: '',
        certificateAgency: '',
        isRegistration: 0,
        certificateTime: null,
        county: '',
        province: '',
        city: '',
        projectAddress: '',
        projectDetailedAddress: '',
        startDate: null,
        progress: null,
        expectedEndDate: null,
        endDate: null,
        buildingScale: null,
        projectStatus: null,
      },
      projectPrepayFormInfo: {
        id: null,
        projectId: null,
        isVatTax: 0,
        vatTaxationBasis: 0,
        vatTax: null,
        isUrbanTax: 0,
        urbanTaxationBasis: 0,
        urbanTax: null,
        isEduAttachTax: 0,
        eduAttachTaxationBasis: 0,
        eduAttachTax: null,
        isLocalEduTax: 0,
        localEduTaxationBasis: 0,
        localEduTax: null,
        isCorpIncomeTax: 0,
        corpIncomeTaxationBasis: 0,
        corpIncomeTax: null,
        isStampTax: 0,
        stampTaxationBasis: 0,
        stampTax: null,
        isWaterConservancyFundTax: 0,
        waterConservancyFundTaxationBasis: 0,
        waterConservancyFundTax: null,
        isPersonalTax: 0,
        personalTaxationBasis: 0,
        personalTax: null,
        isDisabilitiesFundTax: 0,
        disabilitiesFundTaxationBasis: 0,
        disabilitiesFundTax: null,
        isUnionDuesTax: 0,
        unionDuesTaxationBasis: 0,
        unionDuesTax: null,
        isResourceTax: 0,
        resourceTaxationBasis: 0,
        resourceTax: null,
        isRefuseDisposalTax: 0,
        refuseDisposalTaxationBasis: 0,
        refuseDisposalTax: null,
        isEnvironmentalProtectionTax: 0,
        environmentalProtectionTaxationBasis: 0,
        environmentalProtectionTax: null,
      },
      businessLicense: '',
      rules: {

      },
      saveBtnLoading: false
    }
  },
  created () {
    this.isEdit = this.$route.query.isEdit
    if (this.isEdit) {
      this.title = '编辑组织'
      let id = this.$route.query.id
      this.handleSelectproject(id)
      this.renderTable();
    // } else {
    //   this.tableData = this.chanelData
    }

    this.searchContractByProjectId();
    this.getProjectPrepayByProjectId();
    this.getProjectConstructionByProjectId();

  },
  computed: {
    ruless: function () {
      return this.rules
    }
  },
  methods: {
    async renderTable() {
      const {success, data} = await getInfosByOrgId(this.$route.query.id);
      if (success && data.length > 0) {
        this.tableData = data
      // } else {
      //   this.tableData = this.chanelData
      }
    },
    changeNoYes() {
      this.$refs.projectForm.clearValidate()
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status);
    },
    // 点击提交 -> 参数校验
    handleSaveProject() {
      this.$refs.projectForm.validate((valid) => {
        if (valid) this.submitProject();
      });
    },
    // 提交项目信息
    async submitProject() {
      // loading保存按钮阻止反复提交
      this.saveBtnLoading = true;
      let projectData = {
          ...this.formInfo,
          projectPrepay: this.projectPrepayFormInfo,
          projectConstruction: this.projectConstructionFormInfo,
      }
      const { success } = await saveProject(projectData);

      const message = this.formInfo.id > 0 ? '修改' : '新增';
      this.saveBtnLoading = false;
      if (success) {
        this.toast(`${message}项目信息成功`, 'success', () => this.handleCancel('success'));
        if (this.scene == 'Billing' && this.isRetail) {
          this.$emit('edit-finish', this.info);
        }
      } else {
        this.toast(`${message}项目信息失败`, 'error');
      }
    },
    handIsDisabled() {
      return this.isEdit;
    },
    // 合同类型
    contractTypeFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '合同类型':
          if (value === 1) {
            return '收入类';
          } else if (value === 2) {
            return '支出类';
          } else {
            return '';
          }
      }
    },
    // 合同状态
    statusFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '合同状态':
          if (value === 1) {
            return '正常';
          } else if (value === 2) {
            return '终止';
          } else {
            return '';
          }
      }
    },
    // 获取项目涉及预交信息
    getProjectPrepayByProjectId() {
      if (this.formInfo.id) {
        getProjectPrepayByProjectId({
          projectId: this.formInfo.id
        }).then(res => {
          if (res.success && res.data) {
            console.log(res.data)
            this.projectPrepayFormInfo = res.data
          }
        })
      }
    },
    // 获取合同
    handleContract() {
      if (this.formInfo.id) {

      }
    },
    // 获取合同列表
    searchContractByProjectId() {
      if (this.formInfo.id) {
        list({
          projectId: this.formInfo.id
        }).then(res => {
          if (res.success && res.data) {
            console.log(res.data)
            this.contractRecords = res.data.records
          }
        })
      }
    },
    // 获取项目施工信息
    getProjectConstructionByProjectId() {
      if (this.formInfo.id) {
        getProjectConstructionByProjectId({
          projectId: this.formInfo.id
        }).then(res => {
          if (res.success && res.data) {
            console.log(res.data)
            this.projectConstructionFormInfo = res.data
          }
        })
      }
    },
    // 获取组织详情
    handleSelectproject(id) {
      selectproject(id).then(res => {
        if (res.success) {
          console.log(res.data)
          this.formInfo = res.data
          this.imageUrl = res.data.fileUrl
        }
      })
    },
    // 禁止输入特殊字符
    handleNoInputSpecialCharacter (e) {
      let flag = RegexLib.TaxNo
      e.target.value = e.target.value.replace(flag, '')
    },
    // 组织编码唯一性校验
    handleCheckprojectUniqueCode (rule, value, callback) {
      checkprojectUniqueCode(this.formInfo).then(res => {
        if (res.success) {
          if (res.data) {
            callback(new Error('该组织编号已经存在'))
          } else {
            callback()
          }
        }
      })
    },
    // 组织税号校验
    handleCheckprojectTaxNo (rule, value, callback) {
      let param = {
        orgId: this.formInfo.orgId,
        orgName: this.formInfo.orgName
      }
      checkproject(param).then(res => {
        if (res.success) {
          if (res.data) {
            callback(new Error('该组织税号已经存在'))
          } else {
            callback()
          }
        }
      })
    },
    // 营业执照选择
    // handleUploadBusinessLicense (file) {
    //   this.imageUrl = URL.createObjectURL(file.raw)
    //   this.businessLicense = file.raw
    // },
    // 提交审核
    // async handleSubmitReview () {
    //   this.$refs.projectForm.validate(async (valid) => {
    //     if (!valid) return

    //     //默认值
    //     if (!this.formInfo.isElec) {
    //       this.formInfo.isElec = false
    //     }
    //     let org = this.formInfo
    //     // 参数校验
    //     if (this.formInfo.isTaxpayer === 'Y') {
    //       let nameCheck = (org.name.length === 0)
    //       let taxNoCheck = (org.taxNo.length === 0)
    //       if (nameCheck || taxNoCheck) {
    //         this.toast('组织名称或者组织税号不能为空！', 'warning')
    //         return
    //       }
    //     } else {
    //       let nameCheck = (org.name.length === 0)
    //       if (nameCheck) {
    //         this.toast('组织名称不能为空！', 'warning')
    //         return
    //       }
    //     }

    //     let form = new FormData()
    //     form.append('id', org.id.toString())
    //     form.append('parentId', org.parentId.toString())
    //     form.append('name', org.name)
    //     form.append('uniqueCode', org.uniqueCode)
    //     if (this.formInfo.isTaxpayer === 'Y') {
    //       form.append('taxNo', org.taxNo)
    //     }
    //     form.append('phone', org.phone)
    //     form.append('address', org.address)
    //     form.append('contacts', org.contacts)
    //     form.append('contactsWay', org.contactsWay)
    //     form.append('bank', org.bank)
    //     form.append('account', org.account)
    //     form.append('isTaxpayer', org.isTaxpayer)
    //     form.append('taxpayerType', org.taxpayerType)
    //     form.append('isElec', org.isElec)
    //     if (this.businessLicense.length != 0) {
    //       form.append('businessLicense', this.businessLicense)
    //     }
    //     const { success, data } = await saveProject(form)
    //     if (success) {
    //       const orgRes = await ReqprojectGetSelectList()
    //       this.$store.commit('user/SET_projectS', orgRes.data)
    //       this.$router.push({ path: '/system/project/success', query: { project: data } })
    //     }
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }

    }

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

    }
  }

  .create-main {
    width: 100%;
    height: 380px;
    background-color: #fff;
    overflow-x: auto;
    margin-bottom: 24px;

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
      
      .top-right {
        width: 906px;
        height: 20px;
        text-align: right;
      }

    }

    .license-title {
      height: 40px;
      margin: 24px;
      background: rgba(235, 244, 255, 1);
      display: flex;
      align-items: left;
      padding-left: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      span {
        width: 1082px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .license-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;

      .license-img {
        width: 180px;
        height: 240px;
        background: rgba(229, 229, 229, 1);
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        .license-show {
          width: 180px;
          height: 240px;
        }

        img {
          width: 58px;
          height: 58px;
        }
      }

      .el-button {
        width: 120px;
      }
    }
    
    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

    }

    .create-main-row {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      text-align: top;

      .el-form-item {
        width: 160px;
      }

      .el-select {
        width: 100%;
      }
      
        .el-input el-input--suffix {
          width: 100px;
        }
      .el-checkbox__label {
        width: 150px;
      }
    }

  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  .edit-select {
    height: 60px;
  }
  
  .el-input-number {
    width: 200px;
    
    .el-input__inner {
      text-align: right;
    }

  }
</style>
<style>
  .el-form-item__error {
    top: 30px;
  }
</style>
